import styles from "./filters.module.scss";
import Button from "../../../../components/Button";
import TextField from "../../../../components/TexField";

import {ReactComponent as CloseIcon} from "./close-filter.svg";

import useForm from "../../../../hooks/useForm";

import fields from "./fields";
// import initialState from "./initialState";
import FormSubmit from "../../../../components/FormSubmit";

const Filters = ({initialState, back, onSubmit}) => {
    const {state, handleChange, handleSubmit, reset} = useForm({initialState, onSubmit});

    const handleReset = () => {
        reset(true);
    }

    return (
        <form onSubmit={handleSubmit} className={styles.settingWrapper}>
            <CloseIcon className={styles.closeIcon} onClick={back}/>
            <h5 className={styles.settingTitle}>Model Settings</h5>
            <p className={styles.settingLabel}>Product Status</p>
            <div className={styles.settingBlock}>
                <div className={styles.settingItem}>
                    <input onChange={handleChange} checked={state.active} type="checkbox" name="active"/>
                    Active
                </div>
                <div className={styles.settingItem}>
                    <input onChange={handleChange} type="checkbox" checked={state.expired} name="expired"/>
                    Expired
                </div>
                <div className={styles.settingItem}>
                    <input onChange={handleChange} type="checkbox" name="inactive" checked={state.inactive}/>
                    Inactive
                </div>
            </div>
            <p className={styles.settingLabel}>Model Type</p>
            <div className={styles.settingBlock}>
                <div className={styles.settingItem}>
                    <input onChange={handleChange} type="checkbox" name="putting" checked={state.putting}/>
                    Putting Greens
                </div>
                <div className={styles.settingItem}>
                    <input onChange={handleChange} type="checkbox" name="tees" checked={state.tees}/>
                    Tees
                </div>
                <div className={styles.settingItem}>
                    <input onChange={handleChange} type="checkbox" name="fairways" checked={state.fairways}/>
                    Fairways
                </div>
                <div className={styles.settingItem}>
                    <input onChange={handleChange} type="checkbox" name="athletic" checked={state.athletic}/>
                    Athletic Fields – Professional
                </div>
            </div>
            <p className={styles.settingLabel}>Season</p>
            <div className={styles.settingBlock}>
                <div className={styles.settingItem}>
                    <input onChange={handleChange} type="checkbox" name="warm" checked={state.warm}/>
                    Warm
                </div>
                <div className={styles.settingItem}>
                    <input onChange={handleChange} type="checkbox" name="cold" checked={state.cold}/>
                    Cool
                </div>
            </div>
            <TextField value={state.distributorName} onChange={handleChange} {...fields.distributorName} />
            <TextField value={state.salesRep} onChange={handleChange} {...fields.salesRep} />

            <FormSubmit back={handleReset} submitText="Apply" backText="Reset" active={true} type="submit"/>
        </form>
    )
}

export default Filters;