import styles from "./text-field.module.scss";

const TextField = ({ label, ...props}) => {
    return (
        <div className={styles.wrapper}>
            <label className={styles.label}>{label}</label>
            <input className={styles.input} {...props} />
        </div>
    )
}

export default TextField;

TextField.defaultProps = {
    required: false,
    type: "text",
    disabled: false,
}