import styles from "./toggle-button.module.scss";

const ToggleButton = ({collapse, onClick}) => {
    const fullClassName = !collapse ? `${styles.btn} active` : styles.btn;
    // console.log(fullClassName)
    return (
        <div onClick={onClick} className={fullClassName}>
            <span className={styles.line}/>
            <span className={styles.line}/>
            <span className={styles.line}/>
        </div>
    )
}

export default ToggleButton;