import {ReactComponent as Logo} from "./logo-icon.svg";

import FadeGallery from "../../../modules/FadeGallery";

import styles from "./public-page-latoyt.module.scss";

const PublicPageLayout = ({children}) => {
    return (
        <div className={styles.wrapper}>
            <FadeGallery />
            {/*<div className={styles.animation}>*/}
            {/*    <Logo className={styles.logo} />*/}
            {/*</div>*/}
            <div>
                <div className={styles.container}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default PublicPageLayout;