import ActiveLink from "../../../shared/components/ActiveLink";
import Button from "../../../shared/components/Button";

import styles from "./reset-password.module.scss";

const ResetPassword = ({email, onCancel, onConfirm})=> {
    return (
        <div className={styles.wrapper}>
            <p className={styles.text}>Are you sure you want to change your password?</p>
            <p className={styles.text}>If you agree, we wil send you an email to <ActiveLink to="#">{email}</ActiveLink></p>
            <div className={styles.btnWrapper}>
                <Button className={styles.firstBtn} onClick={onCancel}>Cancel</Button>
                <Button active={true} onClick={onConfirm}>Confirm</Button>
            </div>
        </div>
    )
}

export default ResetPassword;