import Navbar from "./Navabar";
import MobileNavbar from "./MobileNavabar";
import Sidebar from "./Sidebar";

import useWindowSize from "../../hooks/useWindowSize";

import styles from "./private-page-layout.module.scss";

const PrivatePageLayout = ({children}) => {
    const {width} = useWindowSize();

    return (
        <div className={styles.wrapper}>
            {width > 780 ? <Navbar /> : <MobileNavbar />}
            <Sidebar />
            {children}
        </div>
    )
}

export default PrivatePageLayout;