import {useState} from "react";
import {useTransition, animated} from "@react-spring/web";

import {ReactComponent as Logo} from "./logo-icon.svg";

import styles from "./fade-gallery.module.scss";

const slides = [
    'bg-1_a5bdpo.png',
    'bg-2_rrlals.png',
    'bg-3_fcffyb.png',
    'bg-4_bk2cgc.png',
];

const FadeGallery = ()=> {
    const [index, set] = useState(0);
    const transitions = useTransition(index, {
        key: index,
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: { duration: 3000 },
        onRest: (_a, _b, item) => {
            if (index === item) {
                set(state => (state + 1) % slides.length)
            }
        },
        exitBeforeEnter: true,
    });

    return (
        <div className={styles.wrapper}>
            {transitions((style, i) => (
                <animated.div
                    className={styles.bg}
                    style={{
                        ...style,
                        backgroundImage: `url(https://res.cloudinary.com/dpzx4xaru/image/upload/v1677585186/predict/${slides[i]})`,
                    }}
                />
            ))}
            <Logo className={styles.logo} />
        </div>
    )
}

export default FadeGallery;