import {useEffect} from "react";
import {createPortal} from "react-dom";

import {ReactComponent as CloseIcon} from "./close-icon.svg";

import styles from "./modal.module.scss";

const modalRoot = document.getElementById("modal-root");
const body = document.body;

const Modal = ({close, children, title}) => {

    const closeModal = (e) => {
        if (e.key === "Escape" || e.currentTarget === e.target) {
            close()
        }
    }

    useEffect(() => {
        body.addEventListener("keydown", closeModal);
        body.style.background = "#F5F5F5"
        return () => body.removeEventListener("keydown", closeModal);
    }, [])

    return createPortal((
        <div className={styles.backdrop}>
            <div className={styles.modal}>
                <div className={styles.heading}>
                    <h5 className={styles.title}>{title}</h5>
                    <div className={styles.closeIcon} onClick={close}>
                        <CloseIcon />
                    </div>

                </div>
                {children}
            </div>
        </div>
    ), modalRoot);
}

export default Modal;