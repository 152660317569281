import {Navigate, Outlet} from 'react-router-dom';
import {useSelector} from "react-redux";

import {getUser} from "../../../../redux/auth/auth-selectors";

const PrivateUserRoute = () => {
    const {role} = useSelector(getUser);

    if(role !== "user") {
        return <Navigate to="/models" />
    }

    return <Outlet/>
}

export default PrivateUserRoute;