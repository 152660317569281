import Button from "../Button";

import styles from "./form-submit.module.scss";

const FormSubmit = ({submitText, backText, disabled, active, submit, back, type})=> {
    return (
        <div className={styles.wrapper}>
            {back && <Button type="button" onClick={back}>{backText}</Button>}
            <Button onClick={submit} disabled={disabled} active={active} type={type}>{submitText}</Button>
        </div>
    )
}

export default FormSubmit;

FormSubmit.defaultProps = {
    disabled: false,
    active: false,
    type: "button",
}