import {useEffect, useState} from "react";

import TextField from "../../../shared/components/TexField";
import Button from "../../../shared/components/Button";

import useForm from "../../../shared/hooks/useForm";

import {requestEditAdminProfile} from "../../../shared/api/auth";

import fields from "./fields";

import styles from "./edit-admin-profile-form.module.scss";

const EditAdminProfileForm = ({onCancel, initialState, afterSubmit})=> {
    const [error, setError] = useState(null);
    const [submit, setSubmit] = useState(false);
    const {state, setState, handleChange, reset} = useForm({initialState});

    useEffect(()=> {
        const fetchEditProfile = async() => {
            try {
                const data = await requestEditAdminProfile(state);
                    reset();
                    afterSubmit(data);
            }
            catch({response}) {
                setError(response.data.message)
            }
        }
        if(submit) {
            fetchEditProfile()
        }
    }, [submit])

    return (
        <div className={styles.wrapper}>
            <TextField value={state.username} onChange={handleChange} {...fields.username} />
            <TextField value={state.firstName} onChange={handleChange} {...fields.firstName} />
            <TextField value={state.email} onChange={handleChange} {...fields.email} />
            <TextField value={state.phone} onChange={handleChange} {...fields.phone} />

            <div className={styles.btnWrapper}>
                <Button onClick={onCancel}>Cancel</Button>
                <Button onClick={()=> setSubmit(true)} active={true}>Save</Button>
            </div>
        </div>
    )
}

export default EditAdminProfileForm;