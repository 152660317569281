import {lazy, Suspense} from "react";
import {Routes, Route} from "react-router-dom";

import PublicRoute from "./shared/routes/PublicRoute";
import PrivateRoute from "./shared/routes/PrivateRoute";
import PrivateUserRoute from "./shared/routes/PrivateRoute/PrivateUserRoute";
import PrivateAdminRoute from "./shared/routes/PrivateRoute/PrivateAdminRoute";

import termOfServiceData from "./data/term-of-service.json";
import privacyPolicyData from "./data/privacy-policy.json";

const SignInPage = lazy(() => import('./pages/SignInPage'));
const SignUpPage = lazy(() => import('./pages/SignUpPage'));
const ForgotPasswordPage = lazy(() => import('./pages/ForgotPasswordPage/ForgotPasswordPage'));
const ResetPasswordPage = lazy(() => import('./pages/ResetPasswordPage'));
const SecureInfoPage = lazy(() => import('./pages/SecureInfoPage'));

const HomePage = lazy(() => import('./pages/HomePage'));
const ProfilePage = lazy(() => import('./pages/ProfilePage'));
const AddModelPage = lazy(() => import('./pages/AddModelPage'));
const SupportPage = lazy(() => import('./pages/SupportPage'));

const ModelsPage = lazy(() => import('./pages/ModelsPage'));
const DocumentsPage = lazy(() => import('./pages/DocumentsPage'));
const ModelConfigPage = lazy(() => import('./pages/ModelConfigPage'));
const ModelsPerUserPage = lazy(() => import('./pages/ModelsPerUserPage'));
const DocumentsPerModelPage = lazy(() => import('./pages/DocumentsPerModelPage'));
const EditFormulasPage = lazy(() => import('./pages/EditFormulasPage'));

const ImpersonatePage = lazy(() => import('./pages/ImpersonatePage'));

const UserRoutes = () => {
    return (
        <Suspense fallback={<p>....Load page</p>}>
            <Routes>
                <Route path="/privacy-policy" element={<SecureInfoPage id="63e622c7bdc9773da31163ca" {...privacyPolicyData} />}/>
                <Route path="/term-of-service" element={<SecureInfoPage id="63e62243bdc9773da31163c8" {...termOfServiceData} />}/>
                <Route element={<PublicRoute/>}>
                    <Route path="/signin" element={<SignInPage/>}/>
                    <Route path="/signup" element={<SignUpPage/>}/>
                    <Route path="/forgot-password" element={<ForgotPasswordPage/>}/>
                    <Route path="/reset-password" element={<ResetPasswordPage/>}/>
                </Route>
                <Route element={<PrivateRoute/>}>
                    <Route element={<PrivateUserRoute />}>
                        <Route path="/" element={<HomePage/>}/>
                        <Route path="/profile" element={<ProfilePage/>}/>
                        <Route path="/add-model" element={<AddModelPage/>}/>
                        <Route path="/support" element={<SupportPage/>}/>
                    </Route>
                    <Route element={<PrivateAdminRoute />}>
                        <Route path="/models" element={<ModelsPage/>}/>
                        <Route path="/models/user/:id" element={<ModelsPerUserPage/>}/>
                        <Route path="/documents" element={<DocumentsPage/>}/>
                        <Route path="/documents/:id/:type" element={<DocumentsPerModelPage/>}/>
                        <Route path="/model-configuration" element={<ModelConfigPage/>}/>
                        <Route path="/formulas" element={<EditFormulasPage/>}/>
                    </Route>
                </Route>
                <Route path="/impersonate" element={<ImpersonatePage/>}/>
            </Routes>
        </Suspense>
    )
};

export default UserRoutes;