import {createSlice} from "@reduxjs/toolkit";

import {logout} from "../auth/auth-operations";

const initialState = {
    search: "",
    other: {
        active: false,
        expired: false,
        inactive: false,
        putting: false,
        tees: false,
        athletic: false,
        fairways: false,
        warm: false,
        cold: false,
        distributorName: "",
        salesRep: "",
    }
};

const filtersSlice = createSlice({
    name: "filters",
    initialState,
    reducers: {
       updateSearch: (store, {payload}) => {
           store.search = payload;
       },
        updateFilters: (store, {payload}) => {
            store.other = {...payload};

        }
    },
    extraReducers: builder => {
        builder.addCase(logout.fulfilled, (store) => {
            store.search = "";
        })
    }
});

export const {updateSearch, updateFilters} = filtersSlice.actions;

export default filtersSlice.reducer;