import {useDispatch} from "react-redux";

import {ReactComponent as CloseIcon} from "./close.svg";
import {ReactComponent as ProfileIcon} from "./profile-icon.svg";
import {ReactComponent as ChangePasswordIcon} from "./change-password-icon.svg";
import {ReactComponent as LogoutIcon} from "./logout-icon.svg";

import {logout} from "../../../../../redux/auth/auth-operations";

import styles from "./profile.module.scss"
import Modal from "../../../../components/Modal";
import ResetPassword from "../../../../../modules/Profile/ResetPassword";
import {useCallback, useEffect, useState} from "react";
import {requestResetPassword} from "../../../../api/auth";
import EditAdminProfileFormProfileForm from "../../../../../modules/Profile/EditAdminProfileForm";

import {updateUser} from "../../../../../redux/auth/auth-slice"


const Profile = ({close, abbreviation, phone, username, firstName, lastName, email, role}) => {
    const dispatch = useDispatch();

    const [modalResetPassword, setModalResetPassword] = useState(false);
    const [modalEditProfile, setModalEditProfile] = useState(false);
    const [resetPassword, setResetPassword] = useState(false);
    const [editProfile, setEditProfile] = useState(false);

    const toggleModalResetPassword = useCallback(() => setModalResetPassword(prevState => !prevState), []);
    const confirmResetPassword = useCallback(()=> {
        setResetPassword(true);
        toggleModalResetPassword();
    }, [])

    const toggleModalEditProfile = useCallback(() => setModalEditProfile(prevState => !prevState), []);

    useEffect(()=> {
        const fetchResetPassword = async() => {
            try {
                const data = await requestResetPassword();
                setResetPassword(false);
                dispatch(logout());
            }
            catch(error) {
                console.log(error.message)
            }
        }

        if(resetPassword) {
            fetchResetPassword();
        }
    }, [resetPassword])

    const afterSubmit = (data)=> {
        const {email, phone, username, firstName} = data;
        dispatch(updateUser({email, phone, username, firstName}))
        toggleModalEditProfile()
    }

    return (
        <>
            <div className={styles.wrapper}>
                <CloseIcon onClick={close} className={styles.close} />
                <div className={styles.abbreviationWrapper}>
                    <span className={styles.abbreviation}>{abbreviation}</span>
                </div>
                <p className={styles.userName}>{firstName} {lastName}</p>
                <p className={styles.email}>{email}</p>
                {role === "admin" && <p className={styles.action} onClick={toggleModalEditProfile}>
                    <ProfileIcon /> Edit profile
                </p>}
                {role === "admin" && <p className={styles.action} onClick={toggleModalResetPassword}>
                    <ChangePasswordIcon /> Change password
                </p>}
                <p className={styles.action} onClick={()=> dispatch(logout())}>
                    <LogoutIcon /> Log out
                </p>
            </div>
            {modalResetPassword && <Modal title="Request Confirmation" close={toggleModalResetPassword}>
                <ResetPassword onCancel={toggleModalResetPassword} onConfirm={confirmResetPassword} email={email} />
            </Modal>}
            {modalEditProfile && <Modal title="Edit Profile" close={toggleModalEditProfile}>
                <EditAdminProfileFormProfileForm onCancel={toggleModalEditProfile} initialState={{phone, username, firstName, email}}  afterSubmit={afterSubmit} />
            </Modal>}
        </>

    )
}

export default Profile;