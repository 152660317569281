import {Navigate, Outlet} from 'react-router-dom';
import {useSelector} from "react-redux";

import {getUser} from "../../../../redux/auth/auth-selectors";

const PrivateAdminRoute = () => {
    const {role} = useSelector(getUser);

    if(role !== "admin") {
        return <Navigate to="/profile" />
    }

    return <Outlet/>
}

export default PrivateAdminRoute;