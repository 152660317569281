import {useState, useCallback} from "react";
import {Link, NavLink} from "react-router-dom"
import {useSelector} from "react-redux";

import {ReactComponent as HomeIcon} from "./icons/home-icon.svg";
import {ReactComponent as ProfileIcon} from "./icons/profile-icon.svg";
import {ReactComponent as SupportIcon} from "./icons/support-icon.svg";
import {ReactComponent as ModelsIcon} from "./icons/models-icon.svg";
import {ReactComponent as DocumentsIcon} from "./icons/documents-icon.svg";
import {ReactComponent as FormulasIcon} from "./icons/formulas-icon.svg";
import {ReactComponent as ModelConfigurationIcon} from "./icons/model-configuration-icon.svg";
import ToggleButton from "./ToggleButton";

import {ReactComponent as Logo} from "./icons/small-logo.svg";

import useWindowSize from "../../../hooks/useWindowSize";

import styles from "./sidebar.module.scss";

import {getUser} from "../../../../redux/auth/auth-selectors";

const Sidebar = () => {
    const {role} = useSelector(getUser);
    const [collapse, setCollapse] = useState(true);

    const {width} = useWindowSize();

    const toggleCollapse = useCallback(()=> setCollapse(prevCollapse => !prevCollapse), [])

    const asideClassName = collapse ? `${styles.sidebar} ${styles.collapse}` : `${styles.sidebar} ${styles.open}`;

    return (
        <aside onMouseOver={!collapse ? toggleCollapse : ()=>{}}  onMouseOut={!collapse ? toggleCollapse : ()=>{}} className={asideClassName}>
            {width > 1200 && <Link className={styles.logo} to="/">
                <Logo />
            </Link>}
            {width < 1200 && <ToggleButton collapse={collapse} onClick={toggleCollapse} />}
            {role === "user" && <div className={styles.userMenu}>
                <NavLink to="/" className={styles.userMenuItem}>
                    <HomeIcon className={styles.userMenuIcon} />
                    {!collapse && "Home"}
                </NavLink>
                <NavLink to="/profile" className={styles.userMenuItem}>
                    <ProfileIcon className={styles.userMenuIcon} />
                    {!collapse && "My Models"}
                </NavLink>
                <NavLink to="/support" className={styles.userMenuItem}>
                    <SupportIcon className={styles.userMenuIcon} />
                    {!collapse && "Support"}
                </NavLink>
            </div>}
            {role === "admin" && <div className={styles.adminMenu}>
                <NavLink to="/models" className={styles.adminMenuItem}>
                    <ModelsIcon className={styles.adminMenuIcon} />
                    {!collapse && "Models"}
                </NavLink>
                <NavLink to="/documents" className={styles.adminMenuItem}>
                    <DocumentsIcon className={styles.adminMenuIcon} />
                    {!collapse && "T&C and PP"}
                </NavLink>
                <NavLink to="/formulas" className={styles.adminMenuItem}>
                    <FormulasIcon className={styles.adminMenuIcon} />
                    {!collapse && "Formulas"}
                </NavLink>
                <NavLink to="/model-configuration" className={styles.adminMenuItem}>
                    <ModelConfigurationIcon className={styles.adminMenuIcon} />
                    {!collapse && "Model Configuration"}
                </NavLink>
            </div>}
        </aside>
    )
}

export default Sidebar;