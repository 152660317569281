const fields = {
    distributorName: {
        name: "distributorName",
        type: "text",
        label: "Distributor name",
        placeholder: "Company",
        required: false,
    },
    salesRep: {
        name: "salesRep",
        type: "text",
        label: "Sales Rep",
        placeholder: "Company",
        required: false,
    },
};

export default fields;