import { createAsyncThunk } from "@reduxjs/toolkit";

export const buildAsyncThunk = (name, service) => {
    return createAsyncThunk(
        name,
        async(data, {rejectWithValue}) => {
            try {
                const result = await service(data);
                return result;
            } catch ({response}) {
                const {status, data} = response;
                const error = {
                    status,
                    message: data.message,
                }
                return rejectWithValue(error);
            }
        }
    )
}

export const pending = (store) => {
    store.loading = true;
    store.error = null;
}

export const rejected = (store, {payload}) => {
    store.loading = false;
    store.error = payload;
}

export const createAbbreviation = (user) => {
    const {role, firstName, lastName, name} = user;
    if(role === "user") {
        return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
    }

    const [first, last] = name.toUpperCase().split(" ");
    return `${first[0]}${last[0]}`;
}
