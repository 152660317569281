import {createSlice} from "@reduxjs/toolkit";

import {signup, model, login, logout, current} from "./auth-operations";
import {pending, rejected, createAbbreviation} from "../../shared/lib/redux";

const initialState = {
    user: {},
    isLogin: false,
    token: "",
    loading: false,
    error: null,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        signupAction: (store, {payload}) => {
            const {token, ...user} = payload;
            store.loading = false;
            const abbreviation = createAbbreviation(user);
            store.user = {...user, abbreviation};
            store.user.currentModel = user.models[0];
            store.token = token;
            store.isLogin = true;
        },
        updateUser: (store, {payload}) => {
            store.user.email = payload.email;
            store.user.phone = payload.phone;
            store.user.username = payload.username;
            store.user.firstName = payload.firstName;
        },
        addModel: (store, {payload}) => {
            store.user.models.push(payload);
        },
        selectModel: (store, {payload}) => {
            store.user.currentModel = payload;
        },
        updateModelSettings: (store, {payload})=> {
            const {currentModel} = store.user;
            store.user.currentModel = {...currentModel, ...payload}
        }
    },
    extraReducers: builder => {
        builder.addCase(signup.pending, pending)
        .addCase(signup.fulfilled, (store, {payload}) => {
            store.loading = false;
            store.user = payload.user;
            store.token = payload.token;
            store.isLogin = true;
        })
        .addCase(signup.rejected, rejected)
            .addCase(login.pending, pending)
            .addCase(login.fulfilled, (store, {payload}) => {
                const {token, ...user} = payload;
                store.loading = false;
                const abbreviation = createAbbreviation(user);
                store.user = {...user, abbreviation};
                if(user.role === "user") {
                    store.user.currentModel = user.models[0];
                }

                store.token = token;
                store.isLogin = true;
            })
            .addCase(login.rejected, rejected)
            .addCase(logout.pending, pending)
            .addCase(logout.fulfilled, (store) => {
                store.loading = false;
                store.user = {};
                store.token = "";
                store.isLogin = false;
            })
            .addCase(logout.rejected, rejected)
            .addCase(current.pending, pending)
            .addCase(current.fulfilled, (store, {payload}) => {
                const {token, ...user} = payload;
                store.loading = false;
                const abbreviation = createAbbreviation(user);
                store.user = {...user, abbreviation};
                if(user.role === "user") {
                    store.user.currentModel = user.models[0];
                }
                store.token = token;
                store.isLogin = true;
            })
            .addCase(current.rejected, (store, {payload}) => {
                store.loading = false;
                store.token = "";
                // store.error = payload;
            })
            .addCase(model.pending, pending)
            .addCase(model.rejected, rejected)
            .addCase(model.fulfilled, (store, {payload}) => {
                const index = store.user.models.findIndex(item => item._id === payload._id);
                store.user.models[index] = {...payload};
            })
    }
});

export const {signupAction, updateUser, addModel, selectModel, updateModelSettings} = authSlice.actions;

export default authSlice.reducer;