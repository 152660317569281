import {useDispatch} from "react-redux";

import {ReactComponent as SearchIcon} from "./search-icon.svg";

import {updateSearch} from "../../../redux/filters/filters-slice";

import styles from "./text-field.module.scss";

const SearchField = () => {
    const dispatch = useDispatch();
    const handleChange = ({target}) => {
        dispatch(updateSearch(target.value))
    }
    return (
        <div className={styles.wrapper}>
            <input onChange={handleChange} className={styles.input} name="search" type="text"  placeholder="Company / Model / Name" />
            <SearchIcon className={styles.icon} />
        </div>
    )
}

export default SearchField;