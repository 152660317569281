import {Navigate, Outlet} from 'react-router-dom';
import {useSelector} from "react-redux";

import PublicPageLayout from "../../layouts/PublicPageLayout";

import useAuth from '../../hooks/useAuth';
import {getUser} from "../../../redux/auth/auth-selectors";

const PublicRoute = () => {
    const isLogin = useAuth();
    const {role} = useSelector(getUser)

    if (isLogin) {
        return role === "user" ? <Navigate to="/"/> : <Navigate to="/models"/>
    }

    return (
        <PublicPageLayout>
            <Outlet/>
        </PublicPageLayout>
    )
}

export default PublicRoute;