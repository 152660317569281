import {useState, useMemo} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Link, useLocation} from "react-router-dom";

import Filters from "./Filtres";

import {ReactComponent as Arrow} from "./icons/arrow.svg";
import {ReactComponent as List} from "./icons/list.svg";
import {ReactComponent as Plus} from "./icons/plus.svg";
import {ReactComponent as CloseIcon} from "./icons/close.svg";
import {ReactComponent as OpenIcon} from "./icons/open.svg";
import {ReactComponent as FilterIcon} from "./icons/filter-icon.svg";

import Container from "../../../components/Container";
import SearchField from "../../../components/SearchField";

import Profile from "./Profile";

import {getUser} from "../../../../redux/auth/auth-selectors";

import {selectModel} from "../../../../redux/auth/auth-slice";

import {updateFilters} from "../../../../redux/filters/filters-slice";

import {getFilters} from "../../../../redux/filters/filters-selectors";

import styles from "./navbar.module.scss";

const formatDocuments = item => {
    const [year, month, day]= item.date.split("-");
    const currentMonth = month[0] > 0 ? Number(month) : Number(month[1]);
    const currentDay = day[0] > 0 ? Number(day) : Number(day[1]);
    return {...item, formatDate: new Date(year, currentMonth - 1, currentDay)}
}

const Navbar = () => {
    const [openFilters, setOpenFilters] = useState(false);
    const [openProfile, setOpenProfile] = useState(false);
    const [openList, setOpenList] = useState(false);
    const [openModels, setOpenModels] = useState(false);
    const {companyName, physicalZipCode, abbreviation, firstName, lastName, role, email, models, phone, username, currentModel} = useSelector(getUser);
    const dispatch = useDispatch();
    const filters = useSelector(getFilters)

    const {pathname} = useLocation();

    const setFilters = (data) => {
        dispatch(updateFilters(data));
        setOpenFilters(false);
    }

    const date = useMemo(()=> {
        const date = new Date();
        const [, month, day, year] = date.toString().split(" ");
        return `${month} ${day}, ${year}`;
    }, []);

    const toggleFilters = () => setOpenFilters(prevState => !prevState);
    const toggleProfile = () => setOpenProfile(prevState => !prevState);
    const toggleModels = () => {
        setOpenModels(prevState => !prevState)
    }
    const handleSelectModel = (data) => {
        setOpenModels(false);
        dispatch(selectModel(data))
    }

    const modelsElements = models ? models.filter(item => item._id !== currentModel._id).map(item => <li onClick={()=>handleSelectModel(item)} key={item._id} className={styles.modelsItem}>{item.courseName}  / {(item?.typeInfo?.displayName ?? item?.modelType)}</li>) : []
    // const analync = currentModel?.documents.find(({type}) => type === "analync");
    // const soil = currentModel?.documents.find(({type}) => type === "soil");

    // const lastAnalync =
    const analync = currentModel?.documents.filter(({type}) => type === "analync").map(formatDocuments).sort((a, b) => a.formatDate - b.formatDate).pop();
    const soil = currentModel?.documents.filter(({type}) => type === "soil").map(formatDocuments).sort((a, b) => a.formatDate - b.formatDate).pop();

    return (
        <nav className={styles.navbar}>
            <Container>
                <div className={styles.row}>
                    {role === "admin" && <SearchField />}
                    {role === "user" && <div className={styles.column}>
                        <span className={styles.company}>{companyName}</span>
                        <span className={styles.zipCode}>ZIP Code: {physicalZipCode}</span>
                    </div>}
                    {role === "user" && currentModel?.courseName ?
                        <div className={styles.field}>
                            <span className={styles.fieldNormal}>{companyName}</span> / {currentModel?.courseName}  / {(currentModel?.typeInfo?.displayName ?? currentModel?.modelType)}
                            <Arrow onClick={toggleModels} className={styles.fieldArrow} />
                            {openModels && <ul className={styles.models}>
                                {modelsElements}
                            </ul>}
                        </div>
                    : null}
                    <div className={styles.column}>
                        {role === "admin" && pathname === "/models" && <FilterIcon onClick={toggleFilters} className={styles.filterIcon} />}
                        {pathname === "/models" && <div style={{display: openFilters ? 'block' : 'none'}}><Filters initialState={filters} back={()=> setOpenFilters(false)} onSubmit={setFilters} /></div>}
                        {role === "user" && <span className={styles.currentDate}>{date}</span>}
                        {role === "user" &&  <div className={styles.list} onMouseOver={()=> setOpenList(true)} onMouseOut={()=> setOpenList(false)}>
                            {/*<div className={styles.countWrapper}>2</div>*/}
                            <List className={styles.listIcon}  />
                            {openList && <div className={styles.listContainer}>
                                <Link style={{textDecoration: "none"}} to="/add-model"><p style={{fontWeight: "bold", cursor: "pointer"}} className={styles.listItem}>
                                    <Plus /> Add New Model
                                </p></Link>
                                {analync && <a target="_blank" href={`https://drive.google.com/file/d/${analync.id}/view?usp=share_link`} className={styles.listItem}>
                                    <span style={{backgroundColor: "#20C997"}} className={styles.listMarker} />
                                    Analync Report
                                </a>}
                                {soil && <a target="_blank" href={`https://drive.google.com/file/d/${soil.id}/view?usp=share_link`} className={styles.listItem}>
                                    <span style={{backgroundColor: "#63C2DE"}} className={styles.listMarker} />
                                    Soil Mechanix Report
                                </a>}
                            </div>}
                        </div>}
                        <div className={styles.profileWrapper}>
                            <span onClick={toggleProfile} className={styles.abbreviation}>{abbreviation}</span>
                            {openProfile ? <OpenIcon />: <CloseIcon />}
                            {openProfile && <Profile close={toggleProfile} phone={phone} username={username} abbreviation={abbreviation} firstName={firstName} lastName={lastName} email={email} role={role} />}
                        </div>
                    </div>
                </div>
            </Container>
        </nav>
    )
}

export default Navbar;