import axios from "axios";

const {REACT_APP_API_URL, REACT_APP_API_URL_DEV} = process.env;
const baseURL = REACT_APP_API_URL || REACT_APP_API_URL_DEV;

const instance = axios.create({
    baseURL,
});

const setToken = (token) => {
    if (token) {
        return instance.defaults.headers.common.authorization = `Bearer ${token}`;
    }
    instance.defaults.headers.common.authorization = "";
}

export const signup = async (data) => {
    const {data: result} = await instance.post("/auth/signup", data);
    return result;
}

export const verifyEmail = async (data) => {
    const {data: result} = await instance.post("/auth/verify-email", data);
    return result;
}

export const resendCode = async (data) => {
    const {data: result} = await instance.post("/auth/resend-verify-code", data);
    return result;
}

export const saveUser = async (data) => {
    const {data: result} = await instance.post("/auth/save-user", data);
    setToken(result.token);
    return result;
}

export const setNewPassword = async (data) => {
    const {data: result} = await instance.post("/auth/set-new-password", data);
    setToken(result.token);
    return result;
}

export const login = async (data) => {
    const {data: result} = await instance.post("/auth/signin", data);
    setToken(result.token);
    return result;
}

export const logout = async () => {
    const data = await instance.get("/auth/logout");
    setToken();
    return data;
}

export const getCurrent = async (token) => {
    try {
        setToken(token);
        const {data} = await instance.get("/auth/current");
        return data;
    } catch (error) {
        setToken();
        throw error;
    }
}

export const getProfile = async () => {
    const {data: result} = await instance.get("/auth/profile");
    return result;
}

export const requestResetPassword = async () => {
    const {data: result} = await instance.get("/auth/reset-password");
    return result;
}

export const requestForgotPassword = async (data) => {
    const {data: result} = await instance.post("/auth/forgot-password", data);
    return result;
}

export const requestEditProfile = async (data) => {
    const {data: result} = await instance.post("/auth/edit-profile", data);
    return result;
}

export const requestEditAdminProfile = async (data) => {
    const {data: result} = await instance.post("/auth/edit-admin-profile", data);
    return result;
}

export const requestSupport = async (data) => {
    const {data: result} = await instance.post("/auth/support", data);
    return result;
}

export const requestEditModel = async (id, data) => {
    const {data: result} = await instance.put(`/models/${id}`, data);
    return result;
}

export const requestEditCustomer = async (id, data) => {
    const {data: result} = await instance.put(`/auth/edit/${id}`, data);
    return result;
}

export const requestResetCustomerPassword = async (id) => {
    const {data: result} = await instance.get(`/auth/reset-password/${id}`);
    return result;
}

export const requestAddNewCustomer = async (data) => {
    const {data: result} = await instance.post(`/auth/add`, data);
    return result;
}

export const sendReport2 = async(data) => {
    const {data: result} = await instance.post(`/auth/send-report2`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return result;
}

export const sendReport = async(data) => {
    const {data: result} = await instance.post(`/auth/send-report`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return result;
}

export const convertReport = async(data) => {
    const {data: result} = await instance.post(`/auth/convert-report`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return result;
}

export default instance;