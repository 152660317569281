import {useEffect} from "react";
import {useDispatch} from "react-redux";

import UserRoutes from "./UserRoutes";

import {current} from "./redux/auth/auth-operations";

import "./shared/styles/style.scss";

function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(current());
    }, [dispatch]);

    return (
        <UserRoutes/>
    );
}

export default App;