import {Navigate, Outlet} from 'react-router-dom';

import PrivatePageLayout from "../../layouts/PrivatePageLayout";

import useAuth from '../../hooks/useAuth';

const PrivateRoute = () => {
    const isLogin = useAuth();

    if (!isLogin) {
        return <Navigate to="/signin"/>
    }

    return (
        <PrivatePageLayout>
            <Outlet/>
        </PrivatePageLayout>
    )
}

export default PrivateRoute;