import { createAsyncThunk } from "@reduxjs/toolkit";

import {buildAsyncThunk} from "../../shared/lib/redux";

import * as api from "../../shared/api/auth";

export const signup = buildAsyncThunk("auth/signup", api.signup);
export const login = buildAsyncThunk("auth/login", api.login);
export const logout = buildAsyncThunk("auth/logout", api.logout);
export const model = createAsyncThunk(
    "auth/model",
    async(data, {rejectWithValue}) => {
        try {
            const {_id, ...newModel} = data;
            const result = await api.requestEditModel(_id, newModel);
            return result;
        } catch ({response}) {
            const {status, data} = response;
            const error = {
                status,
                message: data.message,
            }
            return rejectWithValue(error);
        }
    }
)
// export const editModel = createAsyncThunk(
//     "auth/edit-model",
//     async(data, {rejectWithValue}) => {
//         try {
//             const {_id, newModel} = data;
//             const result = await api.requestEditModel(_id, newModel);
//             return result;
//         } catch ({response}) {
//             const {status, data} = response;
//             const error = {
//                 status,
//                 message: data.message,
//             }
//             return rejectWithValue(error);
//         }
//     }
// )

export const current = createAsyncThunk(
    "auth/current",
    async(_, {rejectWithValue, getState}) => {
        try {
            const {auth} = getState()
            const result = await api.getCurrent(auth.token);
            return result;
        } catch ({response}) {
            const {status, data} = response;
            const error = {
                status,
                message: data.message,
            }
            return rejectWithValue(error);
        }
    }
)