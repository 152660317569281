const fields = {
    email: {
        name: "email",
        type: "email",
        label: "Email address",
        placeholder: "Enter your email address",
        required: true,
    },
    username: {
        name: "username",
        type: "text",
        label: "Username",
        placeholder: "Enter your unique username",
        required: true,
    },
    phone: {
        name: "phone",
        type: "text",
        label: "Phone Number",
        placeholder: "+1 (000) 000 0000",
        required: true,
    },
    firstName: {
        name: "firstName",
        type: "text",
        label: "Name",
        placeholder: "Enter name",
        required: true,
    },
}

export default fields;