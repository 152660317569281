import styles from "./button.module.scss";

const Button = ({children, className, onClick, active, disabled, type}) => {
    const fullClassname = active ? `${styles.btn} ${styles.active}` : styles.btn;

    return <button onClick={onClick} className={`${fullClassname} ${className}`} type={type} disabled={disabled}>{children}</button>
}

export default Button;

Button.defaultProps = {
    className: "",
    onClick: ()=> {},
    active: false,
    disabled: false,
    type: "submit",
}